import React, { useEffect, useState } from 'react'
import {  InputAdornment, TextField, Button, Box, Container,  } from '@material-ui/core'
import { AccountCircle, VpnKey } from '@material-ui/icons'
import jwt_decode from "jwt-decode";

import * as yup from 'yup';
import { useFormik } from "formik";
import { Helmet } from 'react-helmet';
import LoadingButton from '@mui/lab/LoadingButton';
import showPassword from '../../images/show-password.svg';
import hidePassword from '../../images/hide-password.svg';
import { loadGridChangePassword } from '../../helpers/loadGrid';
import { useLoginStyles } from '../../styles/loginStyles';
import { navigate } from '@reach/router';
import backImage from '../../images/back-login.png';
import { useDispatch } from 'react-redux';
import { startResetPassword, startVerifyingToken } from '../../redux/actions/auth';



const TestGet = ( props ) => {

    const classes = useLoginStyles();
    const [visible, setVisible] = useState(false);
    const [visiblePC, setVisiblePC] = useState(false);
    const { data } = loadGridChangePassword();
    const dispatch = useDispatch();
 
    const token = props.location.search.slice(3);

    const validationSchema = yup.object().shape({
        password: yup.string().required("La contraseña es requerida").min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
        passwordConfirm: yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
            [yup.ref("password")],
            "Ambas contraseñas deben ser iguales"
            )
        }).required('La contraseña es requerida').min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres')
      });

      const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            const token = props.location.search.slice(3);
            const decodedJWT = jwt_decode( token );
            let payload = {
                id: parseInt(decodedJWT.id),
                password: values.password,
                passwordConfirm: values.passwordConfirm,
                token: token
            }

            dispatch( startResetPassword( payload ));
            
        }
    });

    
  console.log(props);

    const handleBack = e => {
        navigate('/login')
    }

  return (
                <h1>{token}</h1>
  )
}
export default TestGet;
